<template lang="pug">
.error-page(:class="[statusCode, adaptive]")
  .inner-content
    ui-logo.logo
    h2(v-if="statusCode") {{ statusCode === 'tariff' ? '403' : statusCode }}
    h2
      slot(name="errorMessage")
    .message
      span
        slot(name="errorDescription")
      span(v-if="['403', '404'].includes(statusCode)") Для продолжения работы вернитесь на главную страницу.
    .contact-wrapper
      .support Если Вам кажется, что произошла какая-то ошибка, пожалуйста, обратитесь в поддержку:
      support-contacts(size="medium" :mode="statusCode === 'tariff' ? '403' : statusCode")

    .actions(v-if="statusCode === 'tariff'")
      ui-button(type="secondary" @click="goToTariffs") Выбрать тариф
      ui-button(type="secondary" :loader="logoutLoader" @click="logout") Выйти из аккаунта
    .actions(v-else-if="['403', '404'].includes(statusCode)")
      ui-button(type="secondary" @click="goToMainPage") Перейти на главную

  .inner-content.left
    .alert(v-if="statusCode === 'tariff'")
      img(src="/pics/background/warning.svg")
      span Вам закрыт доступ к системе. Пожалуйста, продлите тариф
    el-empty(v-else :description="emptyDescription")

</template>

<script lang="ts">
import type { PropType } from "vue";

import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { getMainPageNavigateRoute } from "~/router/auth";

import UiButton from "@/components/ui/button/UiButton.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiLogo from "@/components/ui/logo/UiLogo.vue";
import SupportContacts from "@/components/support/SupportContacts.vue";

import useAuthStore from "@/stores/auth/useAuthStore";

export default defineComponent({
  name: "ErrorPage",
  components: {
    SupportContacts,
    UiButton,
    UiLogo,
    UiIcon,
  },
  props: {
    statusCode: {
      type: String as PropType<'403'|'404'|'tariff'>,
      default: ''
    },
    emptyDescription: {
      type: String,
      default: 'Что-то пошло не так',
    },
    adaptive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const router = useRouter();
    const authStore = useAuthStore();

    const logoutLoader = ref(false);

    function goToMainPage() {
      router.push(getMainPageNavigateRoute());
    }

    function goToTariffs() {
      scrollIntoView('tariffs', 'start')
    }

    function logout() {
      authStore.logout(logoutLoader)
    }

    return {
      logout,
      goToTariffs,
      goToMainPage,
      logoutLoader,
    }
  }
})
</script>

<style scoped lang="scss">
.error-page {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 32px;
  padding-top: 164px;
  padding-bottom: 164px;

  background-image: url('/pics/background/blob-outline.svg'), url('/pics/background/blob-fill.svg');
  background-position: right 60%, 80% 80%;
  background-size: 30%, 30%;
  background-repeat: no-repeat, no-repeat;

  &.tariff {
    grid-template-columns: 3fr 2fr;

    background-image: url('/pics/background/app-pic.svg'), url('/pics/background/blob-outline.svg'), url('/pics/background/blob-fill.svg');
    background-position: 90% 30%, 90% 80%, 80% 90%;
    background-size: 40%, 30%, 30%;
    background-repeat: no-repeat, no-repeat, no-repeat;
  }

  .alert {
    width: 100%;
    max-width: 280px;
    min-width: 240px;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    border: 2px solid var(--main-orange-color);
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    align-self: center;

    border-color: #f59c6c;
    background-color: #fdf6ec;

    img {
      width: 80px;
    }

    span {
      text-align: center;
    }
  }

  .inner-content {
    align-self: center;
    display: flex;
    flex-flow: column;
    gap: 24px;

    h1 {
      font-size: 44px;
    }

    .logo {
      width: fit-content;
    }

    .message {
      display: flex;
      flex-flow: column;
      gap: 16px;
      font-size: 16px;
    }

    .contact-wrapper {
      display: flex;
      flex-flow: column;
      gap: 24px;
      margin-top: 24px;
      font-size: 14px;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      gap: 8px;

      :deep(.ui-button) {
        width: 200px;
        justify-content: center;
      }
    }
  }
}

.error-page.adaptive {
  @media (max-width: 1080px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (max-width: 860px) {
    display: flex;
    flex-flow: column;
    gap: 32px;

    background-image: none;

    &.tariff {
      background-image: none;
    }

    .inner-content.left {
      display: none;
    }
  }
}
</style>
